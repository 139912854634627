@import "../../../../stylesheets/sass/partials/responsive_mixins";

.label {
  bottom: 0;
  left: 0;
  display: flex;
  line-height: 1.5;
  width: 100%;
  container-type: inline-size;
  container-name: label;

  @include mobile-breakpoint {
    @include body-3-size;
  }
}

.label__wrapper {
  display: flex;
  flex-direction: column;
  list-style: none outside none;
  width: 100%;
  padding: 0.1rem 0.4rem 1.5rem;
}

.label__title {
  margin-right: 1rem;
  padding-bottom: 0.2rem;

  flex-grow: 1;
  line-height: 1.4;
  overflow: hidden;
  font-weight: 500;
}

.label__subtitle {
  margin-right: 1rem;
  flex-grow: 1;
  line-height: 1;
  overflow: hidden;
  padding-bottom: 0.8rem;
}

.label__prices {
  display: flex;
  justify-content: space-between;
  color: #666;
}

.label__buy_price {
  display: flex;
  justify-content: space-between;
}

.label__extra_spot_price {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.label__prices__discounted {
  color: #999999;
}

.label__prices__urgent {
  color: #BA473B;
  display: flex;
  justify-content: space-between;
}

.label__price {
  flex: none;
}

.label__discountedPriceWrapper {
  display: flex;
  flex-direction: row;
}

.label__discountedPriceWrapper__strikethrough {
  text-decoration: line-through;
}

.label__discountPercentage {
  margin-right: 6px;
  color: #000;
}
